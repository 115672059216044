<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="区域名称" prop="areaName" >-->
<!--        <a-input v-model="form.areaName" placeholder="请输入区域名称" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Name" prop="fullName" >
        <a-input v-model="form.fullName" placeholder="" />
      </a-form-model-item>
<!--      <a-form-model-item label="上级区域ID" prop="parentId" >-->
<!--        <a-input v-model="form.parentId" placeholder="请输入上级区域ID" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="区域等级" prop="level" >-->
<!--        <a-input v-model="form.level" placeholder="请输入区域等级" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="中心点的经纬度" prop="centerLatLon" >-->
<!--        <a-input v-model="form.centerLatLon" placeholder="请输入中心点的经纬度" />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getArea, addArea, updateArea } from '@/api/area/area'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        areaId: null,

        areaName: null,

        fullName: null,

        parentId: null,

        level: null,

        centerLatLon: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        areaId: null,
        areaName: null,
        fullName: null,
        parentId: null,
        level: null,
        centerLatLon: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      // this.reset()
      this.form = row;
      this.formType = 1
      this.open = true
      this.formTitle = 'Create'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const areaId = row ? row.areaId : ids
      getArea(areaId).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = 'Modify'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.areaId !== undefined && this.form.areaId !== null) {
            updateArea(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addArea(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
